import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  Backdrop,
  Button,
} from "@mui/material";
import { Settings, LogOut, MessageCircle, Plus } from "lucide-react";
import { useUserStore } from "../../store/userStore";
import { useThreadStore } from "../../store/threadStore";
import { apiServices } from "../../services/api";
import ThreadsList from "../../components/threads-list/ThreadsList";
import { useAuth0 } from "@auth0/auth0-react";

interface HeaderProps {
  children?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const logout = useUserStore((state) => state.logout);
  const { logout: auth0Logout, user: auth0User } = useAuth0();
  const { fetchThreads } = useThreadStore();

  const [profileAnchorEl, setProfileAnchorEl] =
    React.useState<HTMLElement | null>(null);
  const [chatAnchorEl, setChatAnchorEl] = React.useState<HTMLElement | null>(
    null
  );

  const isActiveRoute = (path: string) => {
    return (
      location.pathname === path || location.pathname.startsWith(`${path}/`)
    );
  };

  const getUserInitials = () => {
    if (!auth0User) return "?";
    const firstName =
      auth0User.given_name || auth0User.name?.split(" ")[0] || "";
    const lastName =
      auth0User.family_name || auth0User.name?.split(" ")[1] || "";
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  };

  const handleChatMenu = async (event: React.MouseEvent<HTMLElement>) => {
    setChatAnchorEl(event.currentTarget);
    await fetchThreads();
  };

  const handleNewChat = () => {
    if (!isActiveRoute("/app/chat/new")) {
      navigate("/app/chat/new");
      handleCloseProfile();
    }
  };

  const handleLogoClick = () => {
    navigate("/app/chat");
  };

  const handleProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleCloseProfile = () => setProfileAnchorEl(null);
  const handleCloseChat = () => setChatAnchorEl(null);

  const handleLogout = async () => {
    try {
      handleCloseProfile();

      apiServices.user.signOut();
      
      logout();

      await auth0Logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });

      setTimeout(() => {
        navigate("/login", { replace: true });
      }, 1000);
    } catch (error) {
      console.error("Logout failed:", error);
      navigate("/login", { replace: true });
    }
  };

  const handleSettings = () => {
    handleCloseProfile();
    navigate("/app/settings");
  };

  const getMenuItemClass = (path?: string) => {
    const baseClass = "flex items-center gap-3 px-4 py-3 text-sm";
    if (!path) return baseClass;

    const isActive = isActiveRoute(path);
    const isDisabled = path === "/app/chat/new" && isActive;

    return `${baseClass} ${
      isActive
        ? "bg-blue-500/20 text-blue-400"
        : "hover:bg-gray-100/10 text-white"
    } ${isDisabled ? "opacity-50 cursor-not-allowed" : ""}`;
  };

  const menuIconClass = "w-5 h-5";

  return (
    <>
      <AppBar
        position="fixed"
        className={theme.palette.mode === "dark" ? "bg-gray-900" : "bg-white"}
        elevation={1}
      >
        <Toolbar className="flex justify-between items-center">
          <div
            className="flex items-center cursor-pointer"
            onClick={handleLogoClick}
          >
            {children}
          </div>

          <div className="flex items-center gap-4">
            <Button
              color="inherit"
              onClick={handleChatMenu}
              className={
                theme.palette.mode === "dark"
                  ? "text-gray-300"
                  : "text-gray-700"
              }
              startIcon={<MessageCircle size={20} />}
            >
              Threads
            </Button>

            <ThreadsList anchorEl={chatAnchorEl} onClose={handleCloseChat} />

            <IconButton onClick={handleProfileMenu} className="ml-2">
              <Avatar className="bg-blue-600">{getUserInitials()}</Avatar>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Backdrop
        open={Boolean(profileAnchorEl)}
        onClick={handleCloseProfile}
        className="bg-gray-900/50 backdrop-blur-sm z-[1200]"
      />

      <Menu
        anchorEl={profileAnchorEl}
        open={Boolean(profileAnchorEl)}
        onClose={handleCloseProfile}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="mt-2"
        slotProps={{
          paper: {
            elevation: 0,
            className:
              "bg-gray-800/95 backdrop-blur-sm border border-gray-700 rounded-lg overflow-hidden min-w-[240px]",
          },
        }}
      >
        {auth0User && (
          <div className="px-4 py-3 border-b border-gray-700">
            <Typography className="font-medium text-base text-white">
              {auth0User.name}
            </Typography>
            <Typography className="text-sm text-gray-400">
              {auth0User.email}
            </Typography>
          </div>
        )}
        <MenuItem 
          onClick={handleNewChat} 
          disabled={isActiveRoute("/app/chat/new")}
          className={getMenuItemClass("/app/chat/new")}
        >
          <Plus className={`${menuIconClass} ${isActiveRoute("/app/chat/new") ? "text-blue-400" : ""}`} />
          <span>New Chat</span>
        </MenuItem>
        <MenuItem
          onClick={handleSettings}
          className={getMenuItemClass("/app/settings")}
        >
          <Settings
            className={`${menuIconClass} ${
              isActiveRoute("/app/settings") ? "text-blue-400" : ""
            }`}
          />
          <span>Settings</span>
        </MenuItem>
        <MenuItem
          onClick={handleLogout}
          className="flex items-center gap-3 px-4 py-3 text-sm text-red-400 hover:bg-gray-100/10"
        >
          <LogOut className={menuIconClass} />
          <span>Logout</span>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Header;
