// src/services/api/settings.ts
import { BaseApiService } from './base';
import { ApiResponse, SettingsResponse, UpdateSettingsRequest } from '../../types';

export class SettingsApiService extends BaseApiService {

  /**
   * Get user settings
   */
  async getSettings(): Promise<ApiResponse<SettingsResponse>> {
    return this.api.get<any, ApiResponse<SettingsResponse>>('/settings');
  }

  /**
   * Update user settings
   */
  async updateSettings(request: UpdateSettingsRequest): Promise<ApiResponse<SettingsResponse>> {
    return this.api.put<any, ApiResponse<SettingsResponse>>('/settings', request);
  }
}

// Create a singleton instance
export const settingsApiService = new SettingsApiService();