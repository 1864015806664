import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeContextProvider } from "./services/contexts/ThemeContext";
import { validateEnv } from "./utils/validateEnv";
import { ENV_VARS } from "../src/utils/envs";

// Validate environment variables
try {
  validateEnv();
  // Log environment for debugging (remove in production)
  console.log("Environment Variables:", {
    API_URL: ENV_VARS.API_URL,
    API_VERSION: ENV_VARS.API_VERSION,
  });
} catch (error) {
  console.error("Environment validation failed:", error);
  // You might want to show a user-friendly error screen here
  // instead of just throwing the error
  throw error;
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <App />
    </ThemeContextProvider>
  </React.StrictMode>
);

reportWebVitals();
