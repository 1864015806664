interface EnvVar {
  key: string;
  required: boolean;
  validate?: (value: string) => boolean;
}

const ENV_VARS: EnvVar[] = [
  {
    key: "REACT_APP_API_URL",
    required: true,
    validate: (value) =>
      value.startsWith("http://") || value.startsWith("https://"),
  },
  {
    key: "REACT_APP_API_VERSION",
    required: true,
    validate: (value) => value.startsWith("v"),
  },
  {
    key: "REACT_APP_TEST_ACCESS_TOKEN",
    required: false,
  },
  {
    key: "REACT_APP_AUTH0_DOMAIN",
    required: true,
    validate: (value) => value.includes(".auth0.com"),
  },
  {
    key: "REACT_APP_AUTH0_CLIENT_ID",
    required: true,
  },
];

export const validateEnv = () => {
  const errors: string[] = [];

  ENV_VARS.forEach(({ key, required, validate }) => {
    const value = (window as any)._env_?.[key];

    // Check if required variable is missing
    if (required && !value) {
      errors.push(`Missing required environment variable: ${key}`);
      return;
    }

    // If value exists and has a validation function, validate it
    if (value && validate && !validate(value)) {
      errors.push(`Invalid value for environment variable: ${key}`);
    }
  });

  if (errors.length > 0) {
    console.error("Environment validation failed:");
    errors.forEach((error) => console.error(`- ${error}`));
    throw new Error(
      "Environment validation failed. Check console for details."
    );
  }
};

export const getEnvVar = (key: string): string => {
  const envValue = (window as any)._env_?.[key];
  
  if (!envValue) {
    throw new Error(`Required environment variable not found: ${key}`);
  }
  
  return envValue;
};
