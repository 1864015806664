// src/services/api/index.ts
import { SettingsApiService } from './settings';
import { ThreadsApiService } from './threads';
import { UserApiService } from './users';
import { MessageApiService } from './messages';

export * from './base';
export * from './users';
export * from './settings';
export * from './threads';
export * from './messages';

// Export convenience object with all services
export const apiServices = {
  user: new UserApiService(),
  settings: new SettingsApiService(),
  threads: new ThreadsApiService(),
  message: new MessageApiService(),
};