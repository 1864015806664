// src/services/api/message.ts
import { BaseApiService } from "./base";

export interface MessageRequest {
  message: string;
  loader?: string;
  threadId?: string;
}

export interface MessageResponse {
  data: {
    threadId: string;
    message: string;
  };
  error: string | null;
}

export class MessageApiService extends BaseApiService {
  /**
   * Send a message to the AI agent
   */
  async sendMessage(
    request: MessageRequest
  ): Promise<MessageResponse> {
    return this.api.post<any, MessageResponse>(
      "/message",
      request
    );
  }

  /**
   * Create an SSE connection for real-time message updates
   */
  createSSEConnection(threadId?: string): EventSource {
    const url = new URL("/sse", "http://localhost:3000");
    if (threadId) {
      url.searchParams.append("threadId", threadId);
    }

    return new EventSource(url.toString(), {
      withCredentials: true,
    });
  }
}

// Create a singleton instance
export const messageApiService = new MessageApiService();