// src/store/threadStore.ts
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { Thread, SendMessageRequest, ApiError } from "../types";
import { apiServices } from "../services/api";
import { useMessageStore } from "./messageStore";

export interface ThreadState {
  threads: Thread[];
  currentThread: Thread | null;
  loading: "idle" | "loading" | "success" | "error";
  error: ApiError | null;
}

export interface ThreadActions {
  fetchThreads: () => Promise<Thread[] | undefined>;
  fetchThreadById: (threadId: string) => Promise<void>;
  sendMessage: (request: SendMessageRequest) => Promise<void>;
  setCurrentThread: (thread: Thread | null) => void;
  setLoading: (state: ThreadState["loading"]) => void;
  setError: (error: ApiError | null) => void;
  deleteThread: (threadId: string) => Promise<void>;
  clearThreads: () => void;
}

export interface ThreadStore extends ThreadState, ThreadActions {}

const sortThreadsByDate = (threads: Thread[]): Thread[] => {
  return [...threads].sort((a, b) => {
    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
  });
};

const filterSystemMessages = (thread: Thread): Thread => {
  return {
    ...thread,
    messages:
      thread.messages?.filter((message) => message.role !== "system") || [],
  };
};

const filterAndSortThreads = (threads: Thread[]): Thread[] => {
  const filteredThreads = threads.map(filterSystemMessages);
  return sortThreadsByDate(filteredThreads);
};

export const useThreadStore = create<ThreadStore>()(
  devtools(
    persist(
      (set, get) => ({
        // Initial state
        threads: [],
        currentThread: null,
        loading: "idle",
        error: null,

        // Actions
        fetchThreads: async () => {
          try {
            set({ loading: "loading", error: null });
            const response = await apiServices.threads.getThreads();
            if (response.error) {
              set({
                error: response.data.error as ApiError,
                loading: "error",
              });
              return;
            }

            const processedThreads = filterAndSortThreads(
              response.data as unknown as Thread[]
            );
            set({
              threads: processedThreads,
              loading: "success",
            });

            return processedThreads;
          } catch (error) {
            set({
              error: error as ApiError,
              loading: "error",
            });
          }
        },

        fetchThreadById: async (threadId: string) => {
          try {
            set({ loading: "loading", error: null });
            const response = await apiServices.threads.getThreadById(threadId);
            const processedThread = filterSystemMessages(response.data as unknown as Thread);
            set({
              currentThread: processedThread,
              loading: "success",
            });
          } catch (error) {
            set({
              error: error as ApiError,
              loading: "error",
            });
          }
        },

        sendMessage: async (request: SendMessageRequest) => {
          try {
            set({ loading: "loading", error: null });
            const response = await apiServices.threads.sendMessage(request);

            // Update current thread with new message
            set({
              currentThread: response.data.thread,
              loading: "success",
            });

            // Update thread in threads list and maintain sort order
            const { threads } = get();
            const updatedThreads = threads.map((thread) =>
              thread.id === response.data.thread.id
                ? response.data.thread
                : thread
            );
            const sortedThreads = sortThreadsByDate(updatedThreads);
            set({ threads: sortedThreads });
          } catch (error) {
            set({
              error: error as ApiError,
              loading: "error",
            });
          }
        },

        setCurrentThread: (thread: Thread | null) =>
          set({ currentThread: thread }),

        setLoading: (state: ThreadState["loading"]) => set({ loading: state }),

        setError: (error: ApiError | null) =>
          set({ error, loading: error ? "error" : "idle" }),

        clearThreads: () =>
          set({
            threads: [],
            currentThread: null,
            loading: "idle",
            error: null,
          }),

        deleteThread: async (threadId: string) => {
          try {
            set({ loading: "loading", error: null });
            const response = await apiServices.threads.deleteThread(threadId);

            if (response.error) {
              set({
                error: response.error as ApiError,
                loading: "error",
              });
              return;
            }

            // Remove the thread from the store
            const { threads, currentThread } = get();
            const updatedThreads = threads.filter(
              (thread) => thread.id !== threadId
            );

            // If the deleted thread was the current thread, clear it
            const newCurrentThread =
              currentThread?.id === threadId ? null : currentThread;

            set({
              threads: updatedThreads,
              currentThread: newCurrentThread,
              loading: "success",
            });

            // If this was the last thread, clear messages and redirect
            if (updatedThreads.length === 0) {
              // Clear messages using messageStore
              useMessageStore.getState().clearMessages();
              // Redirect to new chat
              window.location.href = "/app/chat/new";
            }
          } catch (error) {
            set({
              error: error as ApiError,
              loading: "error",
            });
          }
        },
      }),
      {
        name: "thread-storage",
        partialize: (state) => ({
          threads: state.threads,
          currentThread: state.currentThread,
        }),
      }
    )
  )
);
