import { BaseApiService } from "./base";
import {
  ApiResponse,
  ThreadResponse,
  ThreadsResponse,
  SendMessageRequest,
} from "../../types";
import { useUserStore } from "../../store/userStore";
import { getEnvVar } from "../../utils/validateEnv";

export class ThreadsApiService extends BaseApiService {
  constructor() {
    super();

    this.api.interceptors.request.use((config) => {
      const token = useUserStore.getState().jwtToken;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        config.withCredentials = true;
      }
      return config;
    });
  }

  async getThreads(): Promise<ApiResponse<ThreadsResponse>> {
    return this.api.get<any, ApiResponse<ThreadsResponse>>("/threads");
  }

  async deleteThread(threadId: string): Promise<ApiResponse<void>> {
    return this.api.delete<any, ApiResponse<void>>(`/threads/${threadId}`);
  }

  async getThreadById(threadId: string): Promise<ApiResponse<ThreadResponse>> {
    return this.api.get<any, ApiResponse<ThreadResponse>>(
      `/threads/${threadId}`
    );
  }

  async sendMessage(
    request: SendMessageRequest
  ): Promise<ApiResponse<ThreadResponse>> {
    return this.api.post<any, ApiResponse<ThreadResponse>>("/message", request);
  }

  createSSEConnection(threadId: string): EventSource {
    const apiUrl = getEnvVar("REACT_APP_API_URL");
    const url = new URL("/sse", apiUrl);
    url.searchParams.append("threadId", threadId);

    return new EventSource(url.toString(), {
      withCredentials: true,
    });
  }
}

export const threadsApiService = new ThreadsApiService();
