import React from "react";
import { motion, AnimatePresence, Variants } from "framer-motion";

const LoadingScreen = () => {
  const text = "prime Sales AI Assistant";
  const letters = text.split("");

  const container: Variants = {
    hidden: {
      opacity: 0,
    },
    visible: (i = 1) => ({
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        repeat: Infinity,
        repeatDelay: 1,
        duration: 0.5,
        repeatType: "reverse" as const,
      },
    }),
  };

  const letter: Variants = {
    hidden: {
      opacity: 0,
      x: 20,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
  };

  return (
    <div className="fixed inset-0 bg-white dark:bg-[#1f2937] flex items-center justify-center font-['Barlow_Semi_Condensed']">
      <div className="flex items-center text-4xl md:text-6xl font-semibold tracking-tight">
        <AnimatePresence>
          <motion.div
            key="text-container"
            className="flex items-center"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={container}
          >
            {/* C letter */}
            <motion.span
              key="c-letter"
              className="text-[#ed1854] font-semibold"
              variants={letter}
            >
              C
            </motion.span>

            {/* Rest of the text */}
            {letters.map((char, index) => (
              <motion.span
                key={`letter-${index}`}
                className="text-[#1f2937] dark:text-white font-semibold"
                variants={letter}
              >
                {char}
              </motion.span>
            ))}

            {/* Cursor */}
            <motion.span
              key="cursor"
              className="ml-1 w-[3px] h-14 md:h-16 bg-[#1f2937] dark:bg-white inline-block"
              animate={{
                opacity: [1, 0],
              }}
              transition={{
                duration: 0.8,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "linear",
              }}
            />
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default LoadingScreen;
