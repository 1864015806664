// App.tsx
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AppRouter from "./router";
import { useThemeContext } from "./services/contexts/ThemeContext";
import React, { Suspense } from "react";
import LoadingScreen from "./components/loader/LoadingScreen";
import { Auth0ProviderWithConfig } from './components/auth/Auth0ProviderWithConfig';

import "react-toastify/dist/ReactToastify.css";

function App() {
  const { theme } = useThemeContext();

  return (
    <Auth0ProviderWithConfig>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<LoadingScreen />}>
          <AppRouter />
        </Suspense>
      </ThemeProvider>
    </Auth0ProviderWithConfig>
  );
}

export default App;