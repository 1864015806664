import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { motion } from "framer-motion";
import { Box, useTheme } from "@mui/material";
import { ToastContainer } from "react-toastify";

const MainLayout: React.FC = () => {
  const theme = useTheme();
  const text = "prime AI Sales Assistant";

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        bgcolor: "background.default",
        color: "text.primary",
        transition: theme.transitions.create(["background-color", "color"], {
          duration: theme.transitions.duration.standard,
        }),
        overflow: "hidden",
      }}
    >
      <Header>
        <h1
          className="text-2xl font-[500]"
          style={{
            fontFamily: "Barlow Semi Condensed",
            color: theme.palette.text.primary,
          }}
        >
          <motion.span
            className="inline-block"
            style={{ color: "#ed1854" }}
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
          >
            C
          </motion.span>
          {text.split("").map((char, index) => (
            <motion.span
              key={index}
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{
                duration: 0.1,
                delay: 0.3 + index * 0.05,
                ease: "easeOut",
              }}
              style={{ color: theme.palette.text.primary }}
            >
              {char}
            </motion.span>
          ))}
        </h1>
      </Header>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "background.default",
          transition: theme.transitions.create("background-color", {
            duration: theme.transitions.duration.standard,
          }),
          mt: 8,
          mb: 6,
          p: 2,
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "80vw",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            borderRadius: 2,
            overflow: "auto",
          }}
        >
          <Outlet />
        </Box>
      </Box>

      <Footer />

      <ToastContainer theme={theme.palette.mode} />
    </Box>
  );
};

export { MainLayout };
