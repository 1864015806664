import React from "react";
import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";

const AuthLayout: React.FC = () => {
  const text = "prime AI Sales Assistant";
  
  return (
    <div 
      className="min-h-screen bg-cover bg-center bg-no-repeat"
      style={{ 
        backgroundImage: "url('/assets/images/login_bg.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="min-h-screen w-full backdrop-blur-sm bg-black/30">
        <div className="w-full max-w-screen-xl mx-auto px-4">
          <div className="py-4">
            <h1
              className="text-2xl font-[500] text-white"
              style={{ fontFamily: "Barlow Semi Condensed" }}
            >
              <motion.span
                className="text-[#ed1854] inline-block"
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20
                }}
              >
                C
              </motion.span>
              {text.split("").map((char, index) => (
                <motion.span
                  key={index}
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{
                    duration: 0.1,
                    delay: 0.3 + index * 0.05,
                    ease: "easeOut"
                  }}
                >
                  {char}
                </motion.span>
              ))}
            </h1>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export { AuthLayout };