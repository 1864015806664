import { createTheme, PaletteMode } from '@mui/material';
import React from 'react';
import { getDesignTokens } from '../../theme/theme';
export const useColorTheme = () => {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches;

  const storedTheme = localStorage.getItem('theme') as
    | PaletteMode
    | 'system'
    | null;
  const initialMode = storedTheme ? storedTheme : 'system';

  const [mode, setMode] = React.useState<PaletteMode | 'system'>(initialMode);

  const getEffectiveMode = () => {
    if (mode === 'system') {
      return prefersDarkMode ? 'dark' : 'light';
    }
    return mode;
  };

  const toggleColorMode = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    localStorage.setItem('theme', newMode);
  };

  const effectiveMode = getEffectiveMode();

  const modifiedTheme = React.useMemo(
    () => createTheme(getDesignTokens(effectiveMode)),
    [effectiveMode]
  );

  return {
    theme: modifiedTheme,
    mode,
    toggleColorMode,
    setMode,
  };
};
