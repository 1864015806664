// types/user.ts

// Enums
export type StorageType = "s3" | "file" | "cloud";
export type AIModel = "gpt-4o-mini" | "gpt-4";

// Settings interface
export interface UserSettings {
  accessToken: string | null;
  storage: StorageType;
  model: AIModel;
}

// Base user properties interface
export interface UserProps {
  _id: string;
  email: string;
  settings: UserSettings;
  createdAt: string;
  updatedAt: string;
}

// Response interfaces
export interface SignInResponse {
  user: UserResponse;
  jwtToken: string;
  error: null | {
    message: string;
    code: string;
    status: number;
  };
}

export interface UserResponse {
  _id: string;
  email: string;
  settings: UserSettings;
  createdAt: string;
  updatedAt: string;
}

export interface SignInRequest {
  email: string;
  accessToken: string;
}

// User class
export class User {
  _id: string;
  email: string;
  settings: UserSettings;
  createdAt: Date;
  updatedAt: Date;

  constructor(props: UserProps) {
    this._id = props._id;
    this.email = props.email;
    this.settings = props.settings;
    this.createdAt = new Date(props.createdAt);
    this.updatedAt = new Date(props.updatedAt);
  }

  static createFrom(data: UserProps): User {
    return new User(data);
  }

  deleteAccessToken(): void {
    this.settings.accessToken = null;
    this.updatedAt = new Date();
  }

  toJSON(): UserProps {
    return {
      _id: this._id,
      email: this.email,
      settings: this.settings,
      createdAt: this.createdAt.toISOString(),
      updatedAt: this.updatedAt.toISOString(),
    };
  }
}