// src/utils/env.ts
declare global {
  interface Window {
    _env_?: {
      [key: string]: string;
    };
  }
}

export const getEnvVariable = (key: string): string => {
  if (window._env_ && window._env_[key]) {
    return window._env_[key]!;
  }
  return process.env[key] || "";
};

interface EnvVars {
  API_URL: string;
  API_VERSION: string;
  TEST_ACCESS_TOKEN: string;
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
}

// Constants for environment variables
export const ENV_VARS: EnvVars = {
  API_URL: getEnvVariable("REACT_APP_API_URL"),
  API_VERSION: getEnvVariable("REACT_APP_API_VERSION"),
  TEST_ACCESS_TOKEN: getEnvVariable("REACT_APP_TEST_ACCESS_TOKEN"),
  AUTH0_DOMAIN: getEnvVariable("REACT_APP_AUTH0_DOMAIN"),
  AUTH0_CLIENT_ID: getEnvVariable("REACT_APP_AUTH0_CLIENT_ID"),
};
