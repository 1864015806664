// src/theme/theme.ts
import { createTheme, Components, Theme } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";
import { grey } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    // Add any custom variants if needed
  }

  interface TypographyVariantsOptions {
    // Add any custom variants if needed
  }

  interface Components {
    MuiBox?: {
      styleOverrides?: {
        root?: {
          backgroundColor?: string;
        };
      };
    };
  }
}

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: {
            main: "#D9D9DA",
          },
          secondary: {
            main: "#B5B4B7",
          },
          background: {
            default: grey[50],
            paper: grey[100],
          },
          text: {
            primary: grey[900],
            secondary: grey[800],
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: "#323137",
          },
          secondary: {
            main: "#3F3E44",
          },
          background: {
            default: "#121212",
            paper: "#1D1D1D",
          },
          text: {
            primary: "#ffffff",
            secondary: grey[500],
          },
        }),
  },
});

// Default theme configuration
export const theme = createTheme({
  typography: {
    fontFamily: [
      "Barlow Semi Condensed",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Arial",
      "sans-serif",
    ].join(","),
    h1: {
      fontWeight: 700,
      fontSize: "2.5rem",
    },
    h2: {
      fontWeight: 600,
      fontSize: "2rem",
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.75rem",
    },
    h4: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    h5: {
      fontWeight: 500,
      fontSize: "1.25rem",
    },
    h6: {
      fontWeight: 500,
      fontSize: "1rem",
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem",
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.875rem",
    },
    button: {
      fontWeight: 600,
      textTransform: "none",
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.75rem",
    },
  },
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
  },
  components: {
    MuiBox: {
      defaultProps: {
        sx: {
          bgcolor: "transparent",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          "@font-face": [
            {
              fontFamily: "Barlow Semi Condensed",
              fontStyle: "normal",
              fontDisplay: "swap",
              fontWeight: 400,
            },
          ],
        },
      },
    },
  } as Components<Theme>,
});

export {};
