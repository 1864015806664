import { createTheme, Theme, PaletteMode } from '@mui/material';
import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { useColorTheme } from '../hooks/useColorTheme';

type ThemeContextType = {
  mode: PaletteMode | 'system';
  toggleColorMode: () => void;
  setMode: React.Dispatch<React.SetStateAction<PaletteMode | 'system'>>;
  theme: Theme;
};

export const ThemeContext = createContext<ThemeContextType>({
  mode: 'light',
  toggleColorMode: () => {},
  setMode: () => {},
  theme: createTheme(),
});

export const ThemeContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useColorTheme();
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  return useContext(ThemeContext);
};
