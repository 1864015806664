import React, { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { 
  Menu, 
  MenuItem, 
  ListItemText, 
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton
} from "@mui/material";
import { Plus, Trash2 } from "lucide-react";
import { Thread } from "../../types/thread";
import { useThreadStore } from "../../store/threadStore";
import { useMessageStore } from "../../store/messageStore";
import { formatDistanceToNow } from "date-fns";
import { toast } from "react-toastify";
import { useUserStore } from "../../store/userStore";

interface ThreadsListProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const ThreadsList: React.FC<ThreadsListProps> = ({ anchorEl, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { threadId } = useParams();
  const userId = useUserStore((state) => state.user?._id);
  const { threads = [], setCurrentThread, loading, deleteThread } = useThreadStore();
  const { clearMessages } = useMessageStore();

  const isNewChatRoute = location.pathname === "/app/chat/new";

  // State for delete confirmation modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [threadToDelete, setThreadToDelete] = useState<Thread | null>(null);

  // Filter threads by current user
  const userThreads = threads.filter((thread) => thread.userId === userId);

  const handleChatSelect = (thread: Thread) => {
    onClose();
    setCurrentThread(thread);
    navigate(`/app/chat/${thread.id}`);
  };

  const handleNewChat = () => {
    if (isNewChatRoute) return;
    onClose();
    setCurrentThread(null);
    navigate("/app/chat/new");
    clearMessages();
    setTimeout(() => {
      document.querySelector<HTMLTextAreaElement>(".chat-input")?.focus();
      toast.info("Send your first message to start a new thread", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }, 100);
  };

  const handleDeleteClick = (event: React.MouseEvent, thread: Thread) => {
    event.stopPropagation();
    setThreadToDelete(thread);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!threadToDelete) return;

    try {
      await deleteThread(threadToDelete.id);
      setDeleteModalOpen(false);
      toast.success("Thread deleted successfully");

      // Handle navigation after deletion
      if (threadToDelete.id === threadId) {
        // If we deleted the active thread
        if (userThreads.length <= 1) {
          // If this was the last thread, redirect to new chat
          navigate("/app/chat/new");
          setCurrentThread(null);
        } else {
          // Find the next available thread
          const nextThread = userThreads.find(t => t.id !== threadToDelete.id);
          if (nextThread) {
            navigate(`/app/chat/${nextThread.id}`);
            setCurrentThread(nextThread);
          }
        }
      }
    } catch (error) {
      toast.error("Failed to delete thread");
    }
  };

  const getLastMessageTime = (thread: Thread) => {
    if (!thread?.messages?.length) {
      return "No messages";
    }
    return formatDistanceToNow(new Date(thread.updatedAt), { addSuffix: true });
  };

  const truncateTitle = (title: string, maxLength: number) => {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + "...";
    }
    return title;
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            style: {
              maxHeight: "50vh",
              overflow: "auto",
            },
          },
        }}
      >
        <MenuItem 
          onClick={handleNewChat} 
          className={`text-blue-600 ${isNewChatRoute ? 'opacity-50' : ''}`}
          disabled={isNewChatRoute}
          title={isNewChatRoute ? "Already on new thread" : "Start a new thread"}
        >
          <Plus size={16} className="mr-2" />
          New Thread
        </MenuItem>

        {(() => {
          if (loading === "loading") {
            return (
              <MenuItem disabled className="flex justify-center min-w-[240px]">
                <CircularProgress size={24} />
              </MenuItem>
            );
          } else if (!userThreads || userThreads.length === 0) {
            return (
              <MenuItem disabled className="text-gray-500 min-w-[240px]">
                <ListItemText
                  primary="No threads yet"
                  secondary="Start a new conversation"
                />
              </MenuItem>
            );
          } else {
            return userThreads.map((thread) => (
              <MenuItem
                key={thread.id}
                onClick={() => handleChatSelect(thread)}
                className={`min-w-[240px] ${
                  thread.id === threadId ? "bg-blue-50 dark:bg-blue-900" : ""
                }`}
                title={thread.title || "Untitled Thread"}
              >
                <ListItemText
                  primary={truncateTitle(thread.title || "Untitled Thread", 50)}
                  secondary={`Last updated ${getLastMessageTime(thread)}`}
                />
                <IconButton
                  size="small"
                  onClick={(e) => handleDeleteClick(e, thread)}
                  className="text-red-500 hover:text-red-700"
                >
                  <Trash2 size={16} />
                </IconButton>
              </MenuItem>
            ));
          }
        })()}
      </Menu>

      <Dialog
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Thread?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this thread? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="error" 
            variant="contained" 
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ThreadsList;